import * as React from "react"
import HeaderBar from "../components/HeaderBar"
import FooterBar from "../components/FooterBar"
import CurrentProject from "../components/CurrentProject"
import ProjectSeries from "../components/ProjectSeries"

import ScaraThumb from "../images/project_thumbnails/scara.jpg"
import ScaraCarriage from "../images/scara/carriage_view.jpg"
import ScaraTop from "../images/scara/top_view.jpg"
import ScaraProblems from "../images/scara/assembly_troubles.jpg"
import ImageGallery from "../components/ImageGallery"

const imgs = [
    {
        img: ScaraThumb,
        alt: "Scara Render"
    },
    {
        img: ScaraCarriage,
        alt: "Side view of assembled carriage"
    },
    {
        img: ScaraTop,
        alt: "Top view of assembled SCARA Arm"
    },
    {
        img: ScaraProblems,
        alt: "Problems assembling carriage due to poor tolerancing"
    }
]

const ScaraPage = () => {
    return (
        <div>
            {HeaderBar()}
            <h1>Scara Arm</h1>
            {CurrentProject()}
            {ProjectSeries({
                name: "Scara Arm",
                nextname: "Scara WebApp",
                nextlink: "/scara_webapp",
                prevname: "Scara WebApp",
                prevlink: "/scara_webapp",
            })}
            <p>January 2022 I decided to build a small-ish SCARA style arm. I chose SCARA style because it simplified the mechanics of the forces; the motors only need to deal with inertal forces, not mass and intertia. The motivation came principally from having the coursework to support such a project but never having the opportunity to work on it. Since I took this semester off from school, I finally had time to check this project off the list.</p>
            <p>The design constraints were motivated principally by past experience. I wanted the size to be similar in scale to the Dorna Arm that I used in my <a href="/reu">Summer Research 2019</a>. That is, lifting about 2 LBS at 18 inches or so. I wanted the software to be something easy; while I knew I could write my own stepper controller library, plenty exist already and why bother re-inventing the wheel. Finally, I wanted to keep the costs down. I scavenged some parts from the <a href="/12dprinter">12d Printer project</a> and bought some other things.</p>
            <p>On the design side, I spent time on Google looking for some inspiration. I was drawn to the <a href="https://www.thingiverse.com/thing:2487048">MP SCARA</a> project. The MP SCARA had a triangular structure where a carriage moved on three linear rods in the Z dimension. The first arm joint rotated around one of arms. I liked this for a couple reasons. First, the base seemed more stable than other similar SCARA arms, such as <a href="https://howtomechatronics.com/projects/scara-robot-how-to-build-your-own-arduino-based-robot/">this one</a>, where the entire Z-tower rotates. Secondly, by placing the rotation at a point of the triangle, the first joint could still rotate a good portion of 360 degrees, increasing the working volume. Finally, the brearings-on-bearings construction reminded me of something that I designed for my First Robotics Competition (FRC) team. That item never worked right so this was a chance at a redemption arc of sorts.</p>
            <p>The design happened in SolidWorks. It was relatively uneventful. I designed for the 3D printers at my disposal, which constrained most parts to a 270mm x 270mm area (this was before <a href="/swirl">SwIRL</a> got its comically-large CR10 Max printer). At this time, <a href="/swirl">SwIRL</a> had just gotten its new CNC router, so I designed most parts to be relatively easy to CNC Route, with the assumption that some day this would be made out of a hard plastic like acetal or abs. For example, most parts have a flat face with no undercut (or a cosmetic only undercut that wouldn't be machined). This has the side effect of making most parts easy to print with minimal supports.</p>
            <p>Perhaps the hardest part of the design was finding items in stock. I wanted (somewhat arbitrarily) to be done in under four weeks. This greatly limited my ability to order some more niche products such as bearings and GT2 timing belts. Really, it came down to whatever was in stock on Amazon Prime. For example, the length of the first arm is what it is solely because the only belt around the right length (about ten inches) was a 696 tooth belt. I don't know why someone was selling this particular size, but it works great for me.</p>
            <p>The printing process took a couple of days. My little Anet A8 (<a href="https://www.google.com/search?q=3d+printer+fire&rlz=1C1ONGR_enUS954US954&source=lnms&tbm=isch&sa=X&ved=2ahUKEwjDo9HHya33AhVRnGoFHVtPBbMQ_AUoA3oECAEQBQ&biw=1920&bih=969&dpr=1">the fire hazard printer</a>) worked like a champ. Unfortunately, I under-toleranced a couple things so assembly took a little more work than it should have.  Aside from that, assembly went pretty well. I didn't have the screws I thought I had so the motors are not quite perfectly seated, but that can be fixed easily. I also didn't think about the assembly steps while assembling. I have plenty of experience designing for assembly and writing assembly instructions from my time at <a href="/kidkraft">KidKraft</a> so, if the right steps were followed it would have been easy. However, I was some combination of exited and too full of myself and didn't think about these steps when actually doing the assembly, resulting in the whole thing having to come apart and go back together a couple times.</p>
            <p>The next step then is software. I didn't want to tackle writing all the control myself, at least not at first, so I turned to open source projects. <a href="https://marlinfw.org/">Marlin</a> is an extremely popular open source firmware for controlling 3D printers. It supports multiple kinematics models, including having a profile for "MP SCARA". Unfortunately, this didn't seem to work right: the support for SCARA style robots is very minimal. Additionally, due to the construction of of my SCARA arm, the calculation of the second rotation joint is influenced by the first joint. This is how the original MP SCARA project is built, but Marlin's MP SCARA doesn't seem to account for this? More investigation was needed and I instead opted for a simpler solution: tell Marlin this is a standard printer and do the inverse kinematics when generating GCode, effectively taking Marlin out of the loop.</p>
            <p>With these modifications, the Marlin solution works great. Its a bit overkill: all the temperature control that Marlin does for 3D printers is unused; all the inverse kinematics are done externally. For this project, Marlin is just a way to drive multiple stepper motors (with acceleration) concurrently. Perhaps something simpler like <a href="https://github.com/gnea/grbl">Grbl</a> make more sense, but Grbl can only run three axis where my SCARA arm uses four. Of course, Marlin still does plenty of cool things for me. One example is managing the homing process: I had to tell Marlin where I put the limit switches but the reset of the logic is handled without my input.</p>
            <p>The end effector is a simple vacuum pump from <a href="https://www.ebay.com/itm/174800157529">ebay</a>. Honestly, its a great little kit. Would recommend for any small robotics project needs. Only downside is that the hose sticks out quite far to the side past the vacuum cups. This sometimes makes it awkward to get the manipulator into small areas. The vacuum is toggled on and off through Marlin as a cooling fan.</p>
            <p>In summary, this was (and is) a fun project in what I'm calling "full stack robotics". It leveraged both my hardware and software skills. I'm very happy with the results, but there is still plenty to do. I have been working on integrating the system with a web interface and playing around with a sample use case involving a small laser engraver. Also, I may some day tackle writing my own control software. I think it would be a neat learning experience but also acknowledge its quite a bit of work.</p>
            <ImageGallery value={imgs}/>
            {FooterBar()}
        </div>
    )
}

export default ScaraPage
